<template>

    <screen-3 v-if="screen" />
    <screen-6 v-else />

</template>

<script>
import screen3 from '../screen3';
import screen6 from '../screen6';
import {state} from '../../store';

export default {
    components:{
        screen3,
        screen6
    },
    data(){
        return{
            screen
        }
    },
    mounted(){

        this.screen = true

        setInterval(() => {
            this.screen = !this.screen
        }, state.timeChangeScreen);

    }
}
</script>

<style>

</style>