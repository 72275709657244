<template>
  
    <div class="h-full w-full bg-body p-10">

        <div class="h-full w-full rounded shadow bg-module p-4 flex flex-col">

            <div class="h-20 px-8 w-full flex flex-row justify-start items-center">

                <div class="h-full w-111 flex flex-row justify-start items-center mr-8">
                    <span class="text-white text-2xl">Backoffice del Videowall de Taucerámica</span>
                </div>

                <div v-if="optionSelected == 'Objetivos'" class="h-full flex-1 flex flex-row justify-end items-center relative">

                    <div class="h-full flex-1 flex flex-row justify-center items-center">

                        <div v-if="yearview == 'next'" class="h-6 w-6 cursor-pointer rounded-full bg-box shadow flex flex-row justify-center items-center" @click="yearview = 'act'">
                            <i class="mdi mdi-chevron-left text-white text-xl"></i>
                        </div>

                        <div v-else class="w-6"></div>

                        <span v-if="yearview == 'act'" class="text-xl text-white mx-2">{{actualYear}}</span>
                        <span v-if="yearview == 'next'" class="text-xl text-white mx-2">{{nextYear}}</span>
                        
                        <div v-if="yearview == 'act'" class="h-6 w-6 cursor-pointer rounded-full bg-box shadow flex flex-row justify-center items-center" @click="yearview = 'next'">
                            <i class="mdi mdi-chevron-right text-white text-xl"></i>
                        </div>

                        <div v-else class="w-6"></div>

                    </div>

                    <div class="h-full w-111 flex flex-row justify-center items-center">

                        <div class="h-8 w-8 flex-none rounded-full bg-box shadow ml-auto flex flex-col justify-center items-center cursor-pointer" @click="videowall()">
                            <i class="mdi mdi-monitor-dashboard text-white"></i>
                        </div>
                        <div class="h-8 w-8 flex-none rounded-full bg-box shadow ml-2 flex flex-col justify-center items-center cursor-pointer" @click="logout()">
                            <i class="mdi mdi-power-standby text-danger"></i>
                        </div>

                    </div>

                </div>

            </div>

            <div class="flex-1 px-8 pb-8 flex flex-row relative">

                <div class="h-12 w-1/12 bg-body bg-opacity-25 mr-8">
                
                    <div v-for="(el,index) in options" :key="index" class="h-12 cursor-pointer flex flex-row justify-center items-center hover:bg-box hover:bg-opacity-25 px-4 relative" @click="optionSelected = el">

                        <span class="text-white text-xl z-30">{{el}}</span>
                        
                        <div v-if="optionSelected == el" class="absolute top-0 left-0 h-12 w-48 bg-body bg-opacity-25 overflow-hidden rounded-l-lg z-20">
                            <div class="h-full w-full bg-body bg-opacity-75"></div>
                        </div>

                    </div>
                
                </div>

                <div class="flex-1 bg-body  rounded shadow flex flex-row relative">

                    <div v-if="optionSelected == 'Objetivos'" class="h-full w-full flex flex-row relative">

                        <loader :loading="loading" class="bg-module"/>

                        <div class="h-full w-40 flex flex-col flex-none">
                        
                            <div class="h-12 flex-none flex flex-row justify-center items-center">
                                <span class="text-white">Mes</span>
                            </div>

                            <div v-for="(el,index) in months" :key="index" class="h-full flex flex-row justify-start items-center px-4" :class="{'bg-box bg-opacity-25': index%2==0}">
                                <span class="text-white">{{el}}</span>
                            </div>
                        
                        </div>

                        <div class="h-full w-full flex flex-col border-l-4 border-module">

                            <div class="h-12 flex-none flex flex-row justify-center items-center">
                                <span class="text-white">Objetivo Facturación</span>
                            </div>

                            <div v-for="(el,index) in months" :key="index" class="h-full flex flex-row justify-center items-center px-4" :class="{'bg-box bg-opacity-25': index%2==0}">
                                <div v-if="monthsData" class="cursor-pointer" @click="openEditor(monthsData[index].sales, el, 'Objetivo Facturación', monthsData[index].date)">
                                    <span class="text-lightblue font-semibold">{{monthsData[index].sales || 0 | reduceBigNumbers()}} €</span>
                                </div>
                            </div>

                        </div>

                        <div class="h-full w-full flex flex-col">

                            <div class="h-12 flex-none flex flex-row justify-center items-center">
                                <span class="text-white">Real Facturación</span>
                            </div>

                            <div v-for="(el,index) in months" :key="index" class="h-full flex flex-row justify-center items-center px-4" :class="{'bg-box bg-opacity-25': index%2==0}">
                                <div v-if="monthsData">
                                    <span class="text-white font-semibold">{{monthsData[index].sales_real || 0 | reduceBigNumbers()}} €</span>
                                </div>
                            </div>

                        </div>

                        <div class="h-full w-full flex flex-col border-l-4 border-module">

                            <div class="h-12 flex-none flex flex-row justify-center items-center">
                                <span class="text-white">Objetivo Precio Medio</span>
                            </div>

                            <div v-for="(el,index) in months" :key="index" class="h-full flex flex-row justify-center items-center px-4" :class="{'bg-box bg-opacity-25': index%2==0}">
                                 <div v-if="monthsData" class="cursor-pointer" @click="openEditor(monthsData[index].mediumPrice, el, 'Objetivo Precio Medio', monthsData[index].date)">
                                    <span class="text-lightblue font-semibold">{{monthsData[index].mediumPrice || 0 | reduceBigNumbers()}} €/m<sup>2</sup></span>
                                </div>
                            </div>

                        </div>

                        <div class="h-full w-full flex flex-col">

                            <div class="h-12 flex-none flex flex-row justify-center items-center">
                                <span class="text-white">Real Precio Medio</span>
                            </div>

                            <div v-for="(el,index) in months" :key="index" class="h-full flex flex-row justify-center items-center px-4" :class="{'bg-box bg-opacity-25': index%2==0}">
                                 <div v-if="monthsData">
                                    <span class="text-white font-semibold">{{monthsData[index].medium_price_real || 0 | reduceBigNumbers()}} €/m<sup>2</sup></span>
                                </div>
                            </div>

                        </div>

                        <div class="h-full w-full flex flex-col border-l-4 border-module">

                            <div class="h-12 flex-none flex flex-row justify-center items-center">
                                <span class="text-white">Objetivo Margen</span>
                            </div>

                            <div v-for="(el,index) in months" :key="index" class="h-full flex flex-row justify-center items-center px-4" :class="{'bg-box bg-opacity-25': index%2==0}">
                                 <div v-if="monthsData" class="cursor-pointer" @click="openEditor(monthsData[index].margin, el, 'Objetivo Margen', monthsData[index].date)">
                                    <span class="text-lightblue font-semibold">{{monthsData[index].margin || 0 | reduceBigNumbers()}} %</span>
                                </div>
                            </div>

                        </div>

                        <div class="h-full w-full flex flex-col">

                            <div class="h-12 flex-none flex flex-row justify-center items-center">
                                <span class="text-white">Real Margen</span>
                            </div>

                            <div v-for="(el,index) in months" :key="index" class="h-full flex flex-row justify-center items-center px-4" :class="{'bg-box bg-opacity-25': index%2==0}">
                                 <div v-if="monthsData">
                                    <span class="text-white font-semibold">{{monthsData[index].margin_real || 0 | numberFormat(2)}} %</span>
                                </div>
                            </div>

                        </div>

                    </div>

                    <div v-if="optionSelected == '3 Pantallas'" class="h-full w-full flex flex-col">

                        <div class="h-20 w-full flex flex-row justify-center items-center px-4">
                        
                            <div class="h-12 flex flex-row justify-start items-center">
                                <span class="mr-4 text-white">Tiempo de cadencia</span>
                                <input v-if="timeRefresh" type="number" v-model="timeRefresh" class="bg-box text-white w-12"/>
                                <span class="text-white ml-2">Segs</span>
                            </div>

                        </div>

                        <div class="flex flex-1 min-h-0 w-full">



                        </div>

                        <div class="h-10 rounded-lg bg-lightblue shadow cursor-pointer flex flex-col justify-center items-center px-4 ml-4" @click="changetime()">
                            <span class="text-white">Cambiar</span>
                        </div>

                    </div>

                    <div v-if="editor" class="absolute h-full w-full top-0 left-0 flex flex-col justify-center items-center" style="backdrop-filter:blur(5px)">

                        <div class="h-64 w-1/3 rounded shadow bg-module flex flex-col justify-start p-10 items-center relative">

                            <div class="absolute top-0 right-0 h-10 w-10 rounded-full mt-4 mr-4 flex flex-col justify-center items-center hover:bg-danger hover:bg-opacity-25 cursor-pointer" @click="closeModal()">
                                <i class="mdi mdi-close text-danger text-xl"></i>
                            </div>
                        
                            <span class="text-white text-2xl mb-6">{{selectedCategory}} <small>({{selectedMonth}} {{selectedYear}})</small></span>
                            <input type="number" v-model="selectedValue" class="h-8 flex-none rounded bg-body text-white flex flex-row justify-center items-center">
                            <span class="text-gray text-sm mt-4">Introducir valores sin separación de miles y con coma decimal (no punto)"</span>
                            <div class="h-10 w-32 rounded bg-lightblue shadow mt-4 cursor-pointer flex flex-col justify-center items-center" @click="edit()">
                                <span class="text-white text-xl">Guardar</span>
                            </div>
                        
                        </div>

                    </div>

                </div>


            </div>

        </div>

    </div>

</template>

<script>
import loader from '../../components/loader.vue';
import { actions, state } from '@/store';

export default {
    components:{
        loader
    },  
    data(){
        return{
            options: ['Objetivos', '3 Pantallas'],
            optionSelected: 'Objetivos',
            months: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
            monthsData: false,
            editor: false,
            selectedValue: false,
            selectedMonth: false,
            selectedCategory: false,
            actualYear: this.$moment().format('YYYY'),
            nextYear: this.$moment().add(1,'years').format('YYYY'),
            yearview:'act',
            loading: false,
            selectedDate: false,
            timeRefresh: null
        }
    },
    methods:{
        load(){

            let params = false

            if(this.yearview == 'act'){
                params = this.actualYear
            } else {
                params = this.nextYear
            }

            this.loading = true;

            this.axios.get('/objectives/getyear', {params: {year:params}}).then(response => {

                this.monthsData = response.data.data;

                this.loading = false;

            });

        },
        logout(){
               
            this.axios.post('/logout').then(response => {
                actions.setToken(null)
                this.$router.push( {name:'Login'} )
            }) 
        },
        videowall(){
            this.$router.push({ name:'Home' })
        },
        openEditor(value, month, category, date){

            this.selectedValue = value;
            this.selectedMonth = month;
            this.selectedCategory = category;
            this.selectedDate = date;
            this.editor = true;

        },
        closeModal(){
            this.selectedValue = false;
            this.selectedMonth = false;
            this.selectedCategory = false;
            this.editor = false;
        },
        edit(){

            let test = false

            for (let index = 0; index < this.monthsData.length; index++) {
                
                if(this.monthsData[index].date == this.selectedDate){

                    switch (this.selectedCategory) {
                        
                        case 'Objetivo Facturación':
                            this.monthsData[index].sales = this.selectedValue;
                            test = true;
                        break;

                        case 'Objetivo Precio Medio':
                            this.monthsData[index].mediumPrice = this.selectedValue;
                            test = true;
                        break;

                        case 'Objetivo Margen':
                            this.monthsData[index].margin = this.selectedValue;
                            test = true;
                        break;
                    
                    }

                }

            }

            if(test){

                this.axios.post('/objectives/set', {objectives:this.monthsData}).then(response => {

                    this.$message({
                        message: 'Editado con éxito',
                        type: 'success',
                    });

                    this.editor = false;

                })

            }

        },
        changetime(){
            let value = this.timeRefresh * 1000
            console.log(this.timeRefresh)
            actions.setTimeChangeScreen(value)
        }
    },
    computed:{
        selectedYear(){
            if(this.yearview == 'act'){
                return this.actualYear
            } else {
                return this.nextYear
            }
        }
    },
    watch:{
        yearview(){
            this.load();
        }
    },
    mounted(){
        this.timeRefresh = (state.timeChangeScreen/1000);
        this.load();
    }
}
</script>

<style>

    .el-input__inner{
        background-color: #131622 !important;
        border: #131622 !important;
        color: white !important;
    }

    .el-input-number__increase{
        display: none !important;
        background-color: #131622 !important;
        border: #131622 !important;
    }

    .el-input-number__decrease{
        display: none !important;
        background-color: #131622 !important;
        border: #131622 !important;
    }

    input[type=number]::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }

    input[type=number]{
        text-align: center;
    }

</style>