<template>

    <div class="h-full w-full bg-body flex flex-col justify-center items-center">

        <div class="h-111 w-1/3 bg-module shadow flex flex-col justify-center items-center px-10 max-w-2xl">

            <div class="h-56 w-full flex flex-col justify-center items-center">

                <img src="/img/logotau.png" class="h-20">

            </div>

            <div class="flex-1 w-full flex flex-row">

                <div class="h-full flex-1 flex flex-col justify-center items-center">

                    <input type="text" v-model="form.email" placeholder="User" class="w-11/12 h-10 p-4 rounded-lg bg-box text-white text-xl">
                    <input type="password" v-model="form.password" placeholder="Password" class="w-11/12 h-10 mt-2 rounded-lg p-4 bg-box text-white text-xl">

                </div>

                <div class="h-full w-20 border-l-2 border-box flex flex-col justify-center items-center">

                    <div class="h-12 w-12 rounded-full bg-lightblue-transparent flex flex-col justify-center items-center cursor-pointer" @click="login()">
                        <i class="mdi mdi-send text-lightblue text-2xl"></i>
                    </div>

                </div>

            </div>

            <div class="h-10 w-full flex flex-col justify-center items-center mt-4">

                <span v-if="error" class="text-danger text-xl font-semibold mb-8" style="transition: all .3s">Usuario y/o contraseña incorrectos</span>

            </div>

            <div class="flex-1"></div>
        
        </div>

    </div>

</template>

<script>
    import { Request } from '@/api/components';
    import { actions, state } from '@/store';

    export default {
        name: 'Login',
        components: {
            Request
        },
        data() {
            return {
                form: {
                    email: '',
                    password: ''
                },
                error: false
            };
        },
        methods: {

            login(){

                this.axios.post('/login', this.form ).then(response => {
                    
                    this.error = false;
                    actions.setToken(response.data.token)

                    this.axios.get('/me').then(response => {
                        let user = response.data.data
                        actions.setUser(user)

                        if(response.data.data.admin == 1){
                            this.$router.push( {name:'select'} )
                        } else {

                            this.goroute(user)
                        }
                    })


                }).catch(error => {

                    this.form.email = '';
                    this.form.password = '';
                    this.error = true;

                })

            },
            goroute(user){

                switch (user.email) {
                    case 'screen1@tauceramica.com':
                        this.$router.push( {name:'screenleft'} )
                        break;

                    case 'screen2@tauceramica.com':
                        this.$router.push( {name:'screencenter'} )
                        break;

                    case 'screen3@tauceramica.com':
                        this.$router.push( {name:'screenright'} )
                        break;

                    case 'screen4@tauceramica.com':
                        this.$router.push( {name:'screenleft'} )
                        break;

                    case 'screen5@tauceramica.com':
                        this.$router.push( {name:'screencenter'} )
                        break;

                    case 'screen6@tauceramica.com':
                        this.$router.push( {name:'screenright'} )
                        break;
                
                    default:
                        this.$router.push( {name:'Home'} )
                        break;
                }

            }

        }
    }
</script>

<style scoped>

    .vwtau{
        width: 5760px !important;
        height: 2160px !important;
    }

</style>
2