<template>
  
    <div v-if="token" class="vw bg-body flex-col p-4">

        <europemap :bubbles="europeBubbles"/>

    </div>

</template>

<script>
import europemap from '../../components/europemap.vue';
import { actions, state } from '@/store';

export default {
    components:{
        europemap
    },
    data(){
        return{
            europeBubbles:false
        }
    },
    computed:{
        token(){
            return state.token
        }
    },
    methods:{
        load(){
            
            this.axios.get('/sale_by_sale_type/data').then(response => {
                this.europeBubbles = {
                    margin: response.data.data.billingBubbleMargin,
                    mediumPrice: response.data.data.billingBubbleMediumPrice,
                    objective: response.data.data.billingBubbleObjective
                }
            });

        }
    },
    mounted(){

        if(state.token){

            this.load();

        } else {

            var queryString = window.location.search;
            var urlParams = new URLSearchParams(queryString);
            var user = urlParams.get('user');
            var pass = urlParams.get('pass');

            
            if(user && pass){

                this.axios.post('/login', { email:user, password:pass } ).then(response => {
                    
                    actions.setToken(response.data.token)

                    this.axios.get('/me').then(response => {

                        let user = response.data.data
                        actions.setUser(user)

                       window.location.reload();
                        
                    })

                })

            }

        }

    }

}
</script>

<style>
    .vw{
        height: 1080px;
        width: 1920px;
    }
</style>