<template>

    <screen-1 v-if="screen" />
    <screen-4 v-else />

</template>

<script>
import screen1 from '../screen1';
import screen4 from '../screen4';
import {state} from '../../store';

export default {
    components:{
        screen1,
        screen4
    },
    data(){
        return{
            screen
        }
    },
    mounted(){

        this.screen = true

        setInterval(() => {
            this.screen = !this.screen
        }, state.timeChangeScreen);

    }
}
</script>

<style>

</style>