<template>
  
    <div v-if="token" class="vw flex flex-col p-4 bg-body">

        <div class="h-136 bg-module shadow rounded-lg">
            <loadingorders :lastSyncro="lastSyncro.loadingOrders"/>
        </div>

        <div class="flex-1 min-h-0 mt-4 flex flex-row">

            <div class="h-full w-full mr-8 bg-module shadow rounded-lg">
                <containers :lastSyncro="lastSyncro.Container"/>
            </div>

            <div class="h-full w-full bg-module shadow rounded-lg">
                <stock :lastSyncro="lastSyncro.Stock"/>
            </div>

        </div>

    </div>

</template>

<script>
import loadingorders from '../../components/loadingorders.vue';
import containers from '../../components/containers.vue';
import stock from '../../components/stock.vue';
import { state, actions } from '@/store';

export default {
    components:{
        loadingorders,
        containers,
        stock
    },
    data(){
        return{
            lastSyncro: false
        }
    },
    computed:{
        token(){
            return state.token
        }
    },
    methods:{
        load(){

            this.axios.get('/last_synchronize_date/data').then(response => {

                for (let index = 0; index < response.data.data.length; index++) {
                        
                    if(response.data.data[index].name == 'LoadingOrders'){
                        this.lastSyncro.loadingOrders = response.data.data[index]
                    }

                    if(response.data.data[index].name == 'Container'){
                        this.lastSyncro.Container = response.data.data[index]
                    }

                    if(response.data.data[index].name == 'Stock'){
                        this.lastSyncro.Stock = response.data.data[index]
                    }

                }

            })

        }
    },
    mounted(){
        if(state.token){

            this.load();

        } else {

            var queryString = window.location.search;
            var urlParams = new URLSearchParams(queryString);
            var user = urlParams.get('user');
            var pass = urlParams.get('pass');

            
            if(user && pass){

                this.axios.post('/login', { email:user, password:pass } ).then(response => {
                    
                    actions.setToken(response.data.token)

                    this.axios.get('/me').then(response => {

                        let user = response.data.data
                        actions.setUser(user)

                       window.location.reload();
                        
                    })

                })

            }
        }
    }
}
</script>

<style>

    .vw{
        width: 1920px;
        height: 1080px;
    }

</style>