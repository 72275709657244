<template>
  
    <div v-if="token" class="vw flex flex-col p-4 bg-body">

        <div class="h-158 flex flex-row">

            <div class="h-158 w-full bg-module rounded-lg shadow mr-4">
                <deliverynotes :lastSyncro="lastSyncro.DeliveryNotes"/>
            </div>

            <div class="h-158 w-full bg-module rounded-lg shadow relative">
                <billing :data="billingData" :loading="billingLoading" :lastSyncro="lastSyncro.Sales"/>
            </div>

        </div>

        <div class="flex flex-1 min-h-0 mt-4">

            <div class="h-full w-full bg-module rounded-lg shadow overflow-hidden">
                <billingevolution />
            </div>

        </div>

    </div>

</template>

<script>
import deliverynotes from '../../components/deliverynotes.vue';
import billing from '../../components/billing.vue';
import billingevolution from '../../components/billingevolution.vue';
import { actions, state } from '@/store';

export default {
    components:{
        deliverynotes,
        billing,
        billingevolution
    },
     data(){
        return{
            lastSyncro:{
                DeliveryNotes:false,
                Sales: false
            },
            billingLoading: false,
            billingData: false
        }
    },
    computed:{
        token(){
            return state.token
        }
    },
    methods:{
        load(){

            this.billingLoading = true;

            this.axios.get('/sale_by_sale_type/data').then(response => {


                this.billingData = {
                    ...response.data.data.billing,
                    ...response.data.data.billingBubble
                }

                this.billingLoading = false;

            })

            this.axios.get('/last_synchronize_date/data').then(response => {

                for (let index = 0; index < response.data.data.length; index++) {
                        
                    if(response.data.data[index].name == 'DeliveryNotes'){
                        this.lastSyncro.DeliveryNotes = response.data.data[index]
                    }

                    if(response.data.data[index].name == 'Sales'){
                        this.lastSyncro.Sales = response.data.data[index]
                    }

                }


            })

        }
    },
    mounted(){

        if(state.token){

            this.load();

        } else {

            var queryString = window.location.search;
            var urlParams = new URLSearchParams(queryString);
            var user = urlParams.get('user');
            var pass = urlParams.get('pass');

            
            if(user && pass){

                this.axios.post('/login', { email:user, password:pass } ).then(response => {
                    
                    actions.setToken(response.data.token)

                    this.axios.get('/me').then(response => {

                        let user = response.data.data
                        actions.setUser(user)

                       window.location.reload();
                        
                    })

                })

            }

        }

    }
}
</script>

<style>

    .vw{
        height: 1080px;
        width: 1920px;
    }

</style>