<template>

    <screen-2 v-if="screen" />
    <screen-5 v-else />

</template>

<script>
import screen2 from '../screen2';
import screen5 from '../screen5';
import {state} from '../../store';

export default {
    components:{
        screen2,
        screen5
    },
    data(){
        return{
            screen
        }
    },
    mounted(){

        this.screen = true

        setInterval(() => {
            this.screen = !this.screen
        }, state.timeChangeScreen);

    }
}
</script>

<style>

</style>