<template>
  
    <div v-if="token" class="vw flex flex-col bg-body p-4 relative">

        <div class="h-20 absolute top-0 right-0 mt-10 mr-10 flex flex-row justify-end items-center z-50 ">
            
            <div class="h-20 flex flex-col justify-center items-end mr-6">
                <span class="text-2xl text-white">{{today}}</span>
                <span class="text-2xl text-gray">{{hour}}</span>
            </div>
            
            <img src="/img/logotau.png" class="h-full border-r border-gray pr-4">

            <div class="h-full w-10 flex flex-col justify-center items-center ml-2">

                <div class="h-8 w-8 rounded-full bg-box flex flex-col justify-center items-center shadow cursor-pointer" @click="logout()">
                    <i class="mdi mdi-power-standby text-danger text-xl"></i>
                </div>

                <div v-if="user.admin == 1" class="h-8 w-8 rounded-full shadow bg-box mt-2 flex flex-col justify-center items-center cursor-pointer" @click="backoffice()">
                    <i class="mdi mdi-cogs text-white text-xl"></i>
                </div>

            </div>

        </div>

        <worldmap />

    </div>

</template>

<script>
import worldmap from '../../components/worldmap.vue';
import { actions, state } from '@/store';

export default {
    components:{
        worldmap
    },
    data(){
        return{
            today: false,
            hour: false
        }
    },
    methods:{
        logout(){
            
            this.axios.post('/logout').then(response => {
                actions.setToken(null)
                this.$router.push( {name:'Login'} )
            }) 
        },
        setTime(){

            this.today = this.$moment().format('DD-MM-YYYY'),
            this.hour = this.$moment().format('HH:mm')

        },
        backoffice(){
            this.$router.push({ name:'backoffice' })
        }
    },
    computed:{
        user() {
            return state.user;
        },
        token(){
            return state.token;
        }
    },
    mounted(){

        if(state.token){

            this.setTime();
                setInterval(() => {
                    this.setTime();
                }, 1000);

        } else {

            var queryString = window.location.search;
            var urlParams = new URLSearchParams(queryString);
            var user = urlParams.get('user');
            var pass = urlParams.get('pass');

            
            if(user && pass){

                this.axios.post('/login', { email:user, password:pass } ).then(response => {
                    
                    actions.setToken(response.data.token)

                    this.axios.get('/me').then(response => {

                        let user = response.data.data
                        actions.setUser(user)

                       window.location.reload();
                        
                    })

                })

            }

        }
    }
}
</script>

<style>
    .vw{
        height: 1080px;
        width: 1920px;
    }
</style>