<template>
  
    <div v-if="token" class="vw bg-body p-4 flex flex-col">

        <div class="h-full bg-module rounded-lg shadow">
            <thrown-lines :lastSyncro="lastSyncro.toPrepare"/>
        </div>

        <div class="h-full mt-4 bg-module rounded-lg shadow">
            <infodailylines :lastSyncro="lastSyncro.Orders"/>
        </div>

    </div>

</template>

<script>
import thrownLines from '../../components/thrownLines.vue';
import infodailylines from '../../components/infodailylines.vue';
import { actions, state } from '@/store';

export default {
    components:{
        thrownLines,
        infodailylines
    },
    data(){
        return{
            lastSyncro: false
        }
    },
    methods:{
        load(){

            this.axios.get('/last_synchronize_date/data').then(response => {

                for (let index = 0; index < response.data.data.length; index++) {
                        
                    if(response.data.data[index].name == 'Orders'){
                        this.lastSyncro = response.data.data[index]
                    }

                    if(response.data.data[index].name == 'ToPrepare'){
                        this.lastSyncro.toPrepare = response.data.data[index]
                    }

                }

            })

        }
    },
    computed:{
        token(){
            return state.token
        }
    },
    mounted(){

        if(state.token){

            this.load();

        } else {

            var queryString = window.location.search;
            var urlParams = new URLSearchParams(queryString);
            var user = urlParams.get('user');
            var pass = urlParams.get('pass');

            
            if(user && pass){

                this.axios.post('/login', { email:user, password:pass } ).then(response => {
                    
                    actions.setToken(response.data.token)

                    this.axios.get('/me').then(response => {

                        let user = response.data.data
                        actions.setUser(user)

                       window.location.reload();
                        
                    })

                })

            }

        }

    }
}
</script>

<style>
    .vw{
       height: 1080px;
       width: 1920px; 
    }
</style>